<template>
    <BT-Blade-Items
        :bladeName="bladeName"
        :canClose="canClose"
        :canExportCSV="false"
        :canRefresh="false"
        :canSearch="false"
        canSearchLocal
        :canSelect="false"
        :flexColumn="flexColumn"
        :headers="tableHeaders"
        :height="height"
        hideActions
        :hideBackButton="hideBackButton"
        :hideFooter="false"
        :loading="isLoading"
        :navigation="navigation"
        :onFilter="onFilter"
        :onPullAsync="pullEverything"
        :searchProps="searchProps"
        :title="title">
        <template v-slot:toolbar="{ items }">
            <v-slide-x-transition group>
                <v-btn
                    key="b0"
                    small
                    color="primary mx-1"
                    @click="selectAll(items)">
                    Select All
                </v-btn>
                <v-btn
                    key="b1"
                    small
                    color="primary mx-1"
                    @click="selectAllSuggestions(items)">
                    Select Suggestions
                </v-btn>
                <v-btn
                    key="b2"
                    class="primary mx-1"
                    small
                    @click="showLinked = !showLinked">
                    <v-slide-x-transition hide-on-leave>
                        <v-icon small left v-if="showLinked">mdi-check</v-icon>
                    </v-slide-x-transition>Show Existing Matches
                </v-btn>
                <v-btn
                    v-if="isLengthyArray(items) && items.filter(y => y.isSelected).length > 0"
                    key="b3"
                    @click="sync(items)"
                    color="primary mx-1"
                    small>Sync ({{ items.filter(y => y.isSelected).length }})<v-icon right>mdi-sync-circle</v-icon></v-btn>
            </v-slide-x-transition>
        </template>
        
        <template v-slot:isSelected="{ item }">
            <v-checkbox dense v-model="item.isSelected" class="my-auto py-auto" hide-details small />
        </template>
        
        <template v-slot:blitzit="{ item, items }">
            <v-icon 
                v-if="item.status === 'createLocalItem'"
                @click="selectLocalItem(item, items)"
                color="primary"
                small
                class="mx-1">mdi-pencil-circle</v-icon>

            <v-icon 
                v-if="item.status === 'suggestion'" 
                @click="removeLocalItem(item, items)"
                color="primary"
                small
                class="mx-1">mdi-close</v-icon>

            <span v-if="item.localItem != null">{{ nestVal(item.localItem, localDisplayPath) }}</span>
            <span v-else>new</span>

        </template>

        <template v-slot:middle="{ item }">
            <v-icon 
                v-if="item.status === 'matched'"
                color="primary">mdi-link-lock</v-icon>
            <v-icon 
                v-else-if="item.status === 'createExternalItem'"
                color="primary">mdi-arrow-right-bold</v-icon>
            <v-icon 
                v-else-if="item.status === 'createLocalItem'"
                color="primary">mdi-arrow-left-bold</v-icon>
            <v-icon 
                v-else-if="item.status === 'suggestion'"
                color="primary">mdi-help-circle</v-icon>
            <v-icon 
                v-else-if="item.status === 'unknown'"
                color="primary">mdi-cancel</v-icon>            
        </template>

        <template v-slot:party="{ item, items }">
            <span v-if="item.externalItem != null">{{ nestVal(item.externalItem, syncDisplayPath) }}</span>
            <span v-else>new</span>

            <v-icon
                v-if="item.status === 'suggestion'"
                @click="removeExternalItem(item, items)"
                color="primary"
                small
                class="mx-1">mdi-close</v-icon>

            <v-icon
                v-if="item.status === 'createExternalItem'"
                @click="selectExternalItem(item, items)"
                color="primary"
                small
                class="mx-1">mdi-pencil-circle</v-icon>
        </template>

        <template v-slot:actions="{ items }">
            <BT-Select-Dialog
                canSearch
                :items="localOptions"
                label="Local Items"
                @change="res => { updateLocalItem(res, items) }"
                :showToggle="showLocalOptions"
                :listItemText="localDisplayPath"
                hideButton
                returnObject
                :searchProps="[localDisplayPath]"
                width="450" />

            <BT-Select-Dialog
                canSearch
                :items="externalOptions"
                label="External Party Items"
                @change="res => { updateExternalItem(res, items) }"
                :showToggle="showExternalOptions"
                :listItemText="'ExternalItem.' + syncDisplayPath"
                hideButton
                returnObject
                :searchProps="['ExternalItem.' + syncDisplayPath]"
                width="450" />

            <BT-Snack v-model="msg" />
        </template>

    </BT-Blade-Items>
</template>

<script>
import { compareString } from '~helpers';
import { nestedValue } from '~helpers';

export default {
    name: 'BT-Table-Sync',
    components: {
        BTSelectDialog: () => import('~components/BT-Select-Dialog.vue')
    },
    data: function() {
        return {
            showLinked: false,
            
            isLoading: false,
            isSelectingAll: false,
            msg: null,

            currentSyncable: null,
            localItems: [],
            externalItems: [],
            showLocalOptions: false,
            localOptions: [],
            showExternalOptions: false,
            externalOptions: []
        }
    },
    props: {
        bladeName: null,
        canClose: {
            type: Boolean,
            default: true
        },
        flexColumn: {
            type: Boolean,
            default: false
        },
        height: {
            type: String,
            default: null
        },
        hideBackButton: {
            type: Boolean,
            default: false
        },
        hideBladeHeader: {
            type: Boolean,
            default: false
        },
        navigation: {
            type: String,
            default: null
        },
        onCompare: {
            type: Function,
            default: function (localItem, externalItem) {
                return compareString(nestedValue(localItem, this.localComparePath)) == compareString(nestedValue(externalItem, this.syncComparePath));                
            }
        },
        onPullNewItems: {
            type: Function, //function([{ externalItemID, externalItem }])
            default: null
        },
        party: {
            type: String,
            default: null
        },
        searchProps: {
            type: Array,
            default: null
        },
        title: null
    },  
    computed: {
        externalNavigation() {
            return this.$BlitzIt.navigation.findItem(this.navigation).externalNavigations.find(x => x.name === this.party);
        },
        syncDisplayPath() {
            return this.externalNavigation.syncDisplayPath;
        },
        syncComparePath() {
            return this.externalNavigation.syncComparePath;
        },
        localDisplayPath() {
            return this.externalNavigation.localDisplayPath;
        },
        localComparePath() {
            return this.externalNavigation.localComparePath;
        },
        tableHeaders() {
            var headers =  [];

            //if (this.syncSelect) {
                headers.push({ text: 'Sync', value: 'isSelected', display: true });
            //}

            headers.push({ text: 'BlitzIt', value: 'blitzit', display: true });
            headers.push({ text: '', value: 'middle', display: true });
            headers.push({ text: this.party, value: 'party', display: true });

            return headers;
        }
    },
    methods: {
        nestVal(item, path) {
            return nestedValue(item, path);
        },
        onFilter(list) {
            return this.showLinked ? list : list.filter(x => x.status != 'matched');
        },
        async pullEverything() {
            if (this.externalNavigation == null) {
                this.msg = 'No api route provided';
                return [];
            }

            var r = null;

            try {
                this.isLoading = true;
                
                var localRes = await this.$BlitzIt.store.getAll(this.externalNavigation.localNavigation);
                this.localItems = localRes;

                var syncRes = await this.$BlitzIt.api.getAll(this.navigation, { partyID: this.party });
                this.externalItems = JSON.parse(syncRes.data.data);
                
                r = this.loadEverything(this.externalItems, this.localItems);
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.isLoading = false;
            }

            return r;
        },
        //status options: createLocalItem, createExternalItem, matched, unknown, suggestion
        loadEverything(externalItems, localItems) {
            var rItems = [];
            var usedLocalIDs = [];

            if (externalItems == null || localItems == null) {
                return null;
            }
            
            for (var i = 0; i < externalItems.length; i++) {
                var syncItem = externalItems[i];
                var newSyncable = {
                    localID: syncItem.LocalID,
                    localItem: null,
                    externalItemID: syncItem.ExternalItemID,
                    externalItem: syncItem.ExternalItem,
                    status: null,
                    isSelected: false,
                };

                if (syncItem.LocalID != null && syncItem.ExternalItemID != null) {
                    //has a pre-existing match
                    newSyncable.status = 'matched';
                    newSyncable.localItem = localItems.find(x => x.id == syncItem.LocalID);
                }
                else if (syncItem.LocalID == null && syncItem.ExternalItemID != null) {
                    newSyncable.status = 'createLocalItem';
                }
                else {
                    newSyncable.status = 'unknown';
                }

                if (newSyncable.localItem != null) {
                    usedLocalIDs.push(newSyncable.localID);
                }

                rItems.push(newSyncable);

            }

            for (var ii = 0; ii < localItems.length; ii++) {
                var localItem = localItems[ii];

                if (!usedLocalIDs.some(x => x == localItem.id)) {
                    var foundSyncableItem = rItems
                        .filter(x => x.externalItem != null)
                        .find(x => this.onCompare(localItem, x.externalItem));
                        
                    if (!foundSyncableItem) {
                        //add new syncableItem
                        var newSyncableB = {
                            localID: localItem.id,
                            localItem: localItem,
                            externalItemID: null,
                            externalItem: null,
                            status: 'createExternalItem',
                            isSelected: false,
                        }
                        rItems.push(newSyncableB);
                    }
                    else {
                        foundSyncableItem.localItem = localItem;
                        foundSyncableItem.localID = localItem.id;
                        foundSyncableItem.status = 'suggestion';
                    }
                }
            }

            rItems.orderBy('status');
            return rItems;
        },
        selectAll(items) {
            this.isSelectingAll = !this.isSelectingAll;

            items.forEach(x => {
                if (x.status == 'createLocalItem' || x.status == 'createExternalItem') {
                    x.isSelected = this.isSelectingAll;
                }
            })
        },
        selectAllSuggestions(items) {
            this.isSelectingAll = !this.isSelectingAll;

            items.forEach(x => {
                if (x.status == 'suggestion') {
                    x.isSelected = this.isSelectingAll;
                }
                else {
                    x.isSelected = false;
                }
            })
        },
        async sync(items) {
            var rItems = [];
            var newItemsToPull = [];  //was designed for new customers which need to be injected through the separate Auth Microservice - not the Ordering Microservice
            var selectedItems = items.filter(y => y.isSelected === true);

            selectedItems.forEach(syncItem => {
                var rItem = {
                    localID: syncItem.localID,
                    externalItemID: syncItem.externalItemID
                };

                if (syncItem.status != 'matched') {
                    if (syncItem.isSelected == true) {
                        //status options: createLocalItem, createExternalItem, matched, unknown, suggestion
                        if (syncItem.status === 'createLocalItem') {
                            if (this.externalNavigation.canPull) {
                                if (this.onPullNewItems != null) {
                                    newItemsToPull.push({ externalItemID: syncItem.externalItemID, externalItem: syncItem.externalItem });
                                }
                                else {
                                    //add to collective list
                                    rItems.push(rItem);
                                }
                            }
                        }
                        else if (syncItem.status === 'createExternalItem') {
                            if (this.externalNavigation.canPush) {
                                rItems.push(rItem);
                            }
                        }
                        else {
                            rItems.push(rItem);
                        }
                    }
                }
            })

            if (this.isLengthyArray(rItems)) {
                try {
                    this.isLoading = true;
                    this.$forceUpdate();
                    var r = await this.$BlitzIt.api.patch(this.navigation, rItems, null, '/patch?partyID=' + this.party);
                    var cnt = 0;
                    r.data.data.forEach(rItem => {
                        if (rItem.externalItemID != null) {
                            var ind = items.findIndex(y => y.localID != null && rItem.localID == y.localID || y.externalItemID != null && rItem.externalItemID == y.externalItemID);
                            if (ind > -1) {
                                cnt += 1;
                                items.splice(ind, 1);
                            }
                        }
                    })
                    //items = items.filter(y => !r.data.data.some(rItem => { (y.localID != null && rItem.localID == y.localID) || (y.externalItemID != null && rItem.externalItemID == y.externalItemID) }));
                    this.msg = cnt + ' items synced';
                }
                catch (err) {
                    this.msg = this.extractErrorDescription(err);
                }
                finally {
                    this.isLoading = false;
                }
            }

            if (this.isLengthyArray(newItemsToPull) && this.externalNavigation.canPull && this.onPullNewItems != null) {
                //customize how to add new local items from 3rd party
                this.onPullNewItems(newItemsToPull);
            }
        },
        selectLocalItem(syncableItem, items) {
            if (syncableItem.status == 'suggestion' || syncableItem.status == 'createLocalItem') {
                //proceed
                //find possible items
                var possibleLocalItems = this.localItems.filter(x => !items.some(y => y.status == 'matched' && y.localID == x.id));
                this.currentSyncable = syncableItem;
                this.localOptions = possibleLocalItems;
                this.showLocalOptions = !this.showLocalOptions;
            }
        },
        selectExternalItem(syncableItem, items) {
            if (syncableItem.status == 'suggestion' || syncableItem.status == 'createExternalItem') {
                //proceed
                //find possible items
                var possibleExternalItems = this.externalItems.filter(x => !items.some(y => y.status == 'matched' && x.ExternalItemID == y.externalItemID));
                this.currentSyncable = syncableItem;

                this.externalOptions = possibleExternalItems;
                this.showExternalOptions = !this.showExternalOptions;
            }
        },
        updateLocalItem(res, items) {
            if (res != null) {
                //remove from any existing syncable item
                var localItem = res;

                if (this.currentSyncable != null && this.currentSyncable.localID != localItem.id && !items.some(x => x.status == 'matched' && x.localID == localItem.id)) {
                    //if local item has already been matched, then do not proceed
                    for (var i = 0; i < items.length; i++) {
                        //remove from existing syncables
                        var syncable = items[i];
                        if (syncable.localID == localItem.id) {
                            syncable.localID = null;
                            syncable.localItem = null;
                            syncable.status = 'createLocalItem';
                        }
                    }

                    //add to this current syncable
                    if (this.currentSyncable.localID != null) {
                        //try find another free syncable item
                        var suggestedItem = items.find(x =>
                            x.status == 'createLocalItem' &&
                            x.externalItem != null &&
                            this.onCompare(this.currentSyncable.localItem, x.externalItem) && //compareString(x.externalItem[this.syncItemCompareProp]) == compareString(this.currentSyncable.localItem[this.localItemCompareProp]) &&
                            x.externalItemID != this.currentSyncable.externalItemID);

                        if (suggestedItem != null) {
                            suggestedItem.localID = this.currentSyncable.localID;
                            suggestedItem.localItem = this.currentSyncable.localItem;
                            suggestedItem.status = 'suggestion';
                        }
                        else {
                            //create new item
                            items.push({
                                localID: this.currentSyncable.localID,
                                localItem: this.currentSyncable.localItem,
                                externalItemID: null,
                                externalItem: null,
                                status: 'createExternalItem'
                            });
                        }
                    }

                    this.currentSyncable.localID = localItem.id;
                    this.currentSyncable.localItem = localItem;
                    this.currentSyncable.status = 'suggestion';
                }
                
            }

            this.currentSyncable = null;
        },
        updateExternalItem(res, items) {
            if (res != null) {
                //remove from any existing syncable item
                var externalItem = res;

                if (this.currentSyncable != null && this.currentSyncable.externalItemID != externalItem.ExternalItemID && !items.some(x => x.status == 'matched' && x.externalItemID == externalItem.ExternalItemID)) {
                    //if local item has already been matched, then do not proceed
                    for (var i = 0; i < items.length; i++) {
                        //remove from existing syncables
                        var syncable = items[i];
                        if (syncable.externalItemID == externalItem.ExternalItemID) {
                            syncable.externalItemID = null;
                            syncable.externalItem = null;
                            syncable.status = 'createExternalItem';
                        }
                    }
                    
                    //add to this current syncable
                    if (this.currentSyncable.externalItemID != null) {
                        //try find another free syncable item
                        var suggestedItem = items.find(x =>
                            x.status == 'createExternalItem' &&
                            x.localItem != null &&
                            this.onCompare(x.localItem, this.currentSyncable.externalItem) && //compareString(x.localItem[this.localItemCompareProp]) == compareString(this.currentSyncable.externalItem[this.syncItemCompareProp]) &&
                            x.localID != this.currentSyncable.localID);
                        
                        if (suggestedItem != null) {
                            suggestedItem.externalItemID = this.currentSyncable.ExternalItemID;
                            suggestedItem.externalItem = this.currentSyncable.externalItem;
                            suggestedItem.status = 'suggestion';
                        }
                        else {
                            //create new item
                            items.push({
                                localID: null,
                                localItem: null,
                                externalItemID: this.currentSyncable.externalItemID,
                                externalItem: this.currentSyncable.externalItem,
                                status: 'createLocalItem'
                            });
                        }
                    }

                    this.currentSyncable.externalItemID = externalItem.ExternalItemID;
                    this.currentSyncable.externalItem = externalItem.ExternalItem;
                    this.currentSyncable.status = 'suggestion';

                    // this.clearEmptySyncables();

                    // this.resetPage();
                }
                
            }

            this.currentSyncable = null;
        },
        removeLocalItem(syncableItem, items) {
                //if local item has already been matched, then do not proceed
            if (syncableItem.status != 'matched') {                    
                //then add it
                //try find another free syncable item
                var suggestedItem = items.find(x =>
                    x.status == 'createLocalItem' &&
                    x.externalItem != null &&
                    this.onCompare(syncableItem.localItem, x.externalItem) && //compareString(x.externalItem[this.syncItemCompareProp]) == compareString(syncableItem.localItem[this.localItemCompareProp]) &&
                    x.externalItemID != syncableItem.externalItemID);

                if (suggestedItem != null) {
                    suggestedItem.localID = syncableItem.localID;
                    suggestedItem.localItem = syncableItem.localItem;
                    suggestedItem.status = 'suggestion';
                }
                else {
                    //create new item
                    items.push({
                        localID: syncableItem.localID,
                        localItem: syncableItem.localItem,
                        externalItemID: null,
                        externalItem: null,
                        status: 'createExternalItem'
                    });
                }     

                syncableItem.localID = null;
                syncableItem.localItem = null;
                syncableItem.status = 'createLocalItem';

                items.orderBy('status');

                //this.resetPage();
            }                
            
        },
        removeExternalItem(syncableItem, items) {
            if (syncableItem.status != 'matched') {
                //if local item has already been matched, then do not proceed                    
                var suggestedItem = items.find(x =>
                    x.status == 'createExternalItem' &&
                    x.localItem != null &&
                    this.onCompare(x.localItem, syncableItem.externalItem) && //compareString(x.localItem[this.localItemCompareProp]) == compareString(syncableItem.externalItem[this.syncItemCompareProp]) &&
                    x.localID != syncableItem.localID);
                
                if (suggestedItem != null) {
                    suggestedItem.externalItemID = syncableItem.ExternalItemID;
                    suggestedItem.externalItem = syncableItem.externalItem;
                    suggestedItem.status = 'suggestion';
                }
                else {
                    //create new item
                    items.push({
                        localID: null,
                        localItem: null,
                        externalItemID: syncableItem.externalItemID,
                        externalItem: syncableItem.externalItem,
                        status: 'createLocalItem'
                    });
                }    

                syncableItem.externalItemID = null;
                syncableItem.externalItem = null;
                syncableItem.status = 'createExternalItem';

                items.orderBy('status');

                //this.resetPage();
            }
        }
    }
}
</script>